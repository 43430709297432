<template> 
  <v-container class="ma-auto pa-0" fluid>
        <v-card class="mx-auto" outlined>
            <v-toolbar class="custom-light-gray" flat>
                <v-toolbar-title>
                    <transition name="slide-Y" mode="out-in">
                        <div v-if="Search.SearchBox" key="slide-1">
                            <v-text-field
                                v-model="Search.SearchTerm"
                                :label="$t('search')"
                                :placeholder="$t('search')"
                                class="search-box"
                                color="custom-accent"
                                rounded
                                clearable
                                outlined
                                dense
                                @click:clear="Search.SearchTerm='';search(Search.SearchTerm)"
                                @keyup="search(Search.SearchTerm)"
                                hide-details
                                @focus="Search.SearchFocus=true"
                                @blur="Search.SearchFocus=false"
                                return-object>
                            </v-text-field>
                        </div>
                        <v-menu transition="slide-y-transition" content-class="elevation-1" v-else
                            bottom
                            min-width="250"
                            rounded
                            offset-y>
                            <template v-slot:activator="{ on: menu, attrs }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on: tooltip }">
                                        <v-btn :title="$t(selectedCategory.title)" text v-bind="attrs" v-on="{ ...tooltip, ...menu }" class="ma-0 pa-1 text--secondary">
                                            <v-icon>{{ selectedCategory.icon }}</v-icon><span class="ma-auto mx-1">{{$t(selectedCategory.title)}}</span> <v-icon small>mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{$t(selectedCategory.title)}}</span>
                                </v-tooltip>
                            </template>
                            <v-card>
                                <v-list text>
                                    <v-list-item-group mandatory color="primary" v-model="selectedCategory.id">
                                        <v-list-item v-for="(category,i) in categories" :key="i" :value="category.id" @click="setCategory(category)" :disabled="category.disabled" dense>
                                            <v-list-item-icon><v-icon>{{ category.icon }}</v-icon></v-list-item-icon>
                                            <v-list-item-content>{{ $t(category.title) }}</v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-card>
                        </v-menu>
                    </transition>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="ml-2" icon @click="Search.SearchBox=!Search.SearchBox;Search.SearchTerm='';search(Search.SearchTerm)" v-bind="attrs" v-on="on" :title="$t('search')">
                            <transition name="fade-rotate" mode="out-in">
                                <v-icon v-if="Search.SearchBox" key="icon-fade-1">mdi-close</v-icon>
                                <v-icon v-else key="icon-fade-2">mdi-magnify</v-icon>
                            </transition>
                        </v-btn>
                    </template>
                    <span>{{$t('search')}}</span>
                </v-tooltip>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" @click="refresh" :title="$t('refresh')">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>{{$t('refresh')}}</span>
                </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <transition name="fade" mode="out-in" v-if="loading">
                <v-sheet height="440" flat class="pa-0 ma-0 overflow-auto">
                    <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
                    <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
                    <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
                </v-sheet>
            </transition>
            <transition name="fade" mode="out-in" v-if="Search.SearchResult.length==0 && !loading">
                <v-sheet height="440" class="text-center ma-0 pa-0">
                    <v-container class="pa-0 ma-0" fluid fill-height>
                        <v-row class="pa-0 ma-0" align="center" justify="center">
                            <v-col class="ma-0 pa-0"><i class="ma-0 pa-auto justify-center title font-weight-light text--secondary">{{$t("noresult")}}.</i></v-col>
                        </v-row>
                    </v-container>
                </v-sheet>
            </transition>
            <transition name="fade" mode="out-in" v-if="Search.SearchResult.length && !loading">
                <v-list class="pa-0 overflow-auto" height="440" two-line>
                    <v-list-item-group v-model="selectedEmail" @change="selectEmail" active-class="custom-accent--text">
                        <template v-for="(item, i) in  Search.SearchResult.slice(0,itemsperpage * page)">
                            <v-tooltip right :key="i">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item v-bind="attrs" v-on="on" :key="item.name" :title="item.status==0? $t('new'):item.status==1?$t('seen'):item.status==2?$t('replied'):''" :value="item" :class="item.status==0?'primary--text':item.status==2?'disabled-list':''">
                                        <v-list-item-content>
                                            <v-list-item-title class="text-capitalize" v-text="item.name"></v-list-item-title>

                                            <v-list-item-subtitle
                                            class="text--primary text-capitalize"
                                            v-text="item.subject"
                                            ></v-list-item-subtitle>

                                            <v-list-item-subtitle v-text="item.content"></v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-badge v-bind="attrs" v-on="on" v-if="item.status==0" color="primary" dot></v-badge>
                                            <v-list-item-action-text>{{item.created_at | fromNow}}</v-list-item-action-text>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon v-bind="attrs" v-on="on" @click.stop="replyEmail(item)" :title="$t('reply')">
                                                        <v-icon :color="item.status==0?'primary':''">mdi-reply</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{$t('reply')}}</span>
                                            </v-tooltip>
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>
                                <span>{{ item.status==0? $t('new'):item.status==1?$t('seen'):item.status==2?$t('replied'):''}}</span>
                            </v-tooltip>
                            <v-divider
                                :key="'divider-'+i"
                            ></v-divider>
                        </template>
                    </v-list-item-group>
                    <v-row class="pa-0 ma-0">
                        <v-col class="ma-auto pa-0" cols="auto">
                            <v-tooltip bottom v-if="itemsperpage*page < Search.SearchResult.length && !loading">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="ma-auto" icon x-large v-bind="attrs" v-on="on" @click.stop="viewmore" :title="$t('viewmore')">
                                        <v-icon x-large>mdi-chevron-down</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('viewmore')}}</span>
                            </v-tooltip>
                            <v-tooltip bottom v-if="itemsperpage*page >= Search.SearchResult.length && !loading && page>1">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="ma-auto" icon x-large v-bind="attrs" v-on="on" @click.stop="viewless" :title="$t('viewless')">
                                        <v-icon x-large>mdi-chevron-up</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('viewless')}}</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-list>
            </transition>
        </v-card>
  </v-container>
</template>

<script>
import { mapGetters,mapActions } from "vuex";

export default({
    
    props: ['contacts','categories','selectedCategory','loading'],
    data() {
        return {
            itemsperpage:7,
            page:1,
            selectedEmail: undefined,
            Search:{
                SearchTerm:'',
                SearchBox:false,
                SearchLoading:true,
                SearchFocus:false,
                SearchResult:[],
            },
        }
    },
    components: { },
    computed: {
        ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
    },
    created(){
        this.Search.SearchResult = [...this.contacts];
    },
    watch:{},
    methods: {
        search(item){
            this.Search.SearchLoading=true
            if(item)
                this.Search.SearchResult = this.contacts.filter(contact => {
                    return contact.name?.toLowerCase().includes(item.toLowerCase()) || contact.email?.toLowerCase().includes(item.toLowerCase()) || contact.subject?.toLowerCase().includes(item.toLowerCase()) || contact.content?.toLowerCase().includes(item.toLowerCase());
                });
            else
                this.Search.SearchResult = [...this.contacts];
            this.selectedEmail = undefined;
            this.$emit('selectEmail',this.selectedEmail);
            this.Search.SearchLoading=false;
        },
        viewmore(){
            this.page+=1;
        },
        viewless(){
            if(this.page>1)
                this.page-=1;
        },
        setCategory(category){
            this.$emit('setCategory',category);
        },
        selectEmail(email){
            this.$emit('selectEmail',email);
        },
        replyEmail(email){
            this.$emit('replyEmail',email);
        },
        refresh(){
            this.selectedEmail = undefined;
            this.$emit('selectEmail',this.selectedEmail);
            this.retrieveContacts();
        },
        
        ...mapActions('contact',["retrieveContacts"]),
    }
})
</script>
<style scoped>
.disabled-list{
    opacity: 0.6;
}
</style>

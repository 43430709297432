
<template>
  <v-container class="ma-auto pa-0" fluid v-if="selectedEmail">
        <v-card class="mx-auto" outlined>
            <v-toolbar class="custom-light-gray" flat>
                <v-bottom-navigation class="elevation-0 transparent">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="v-btn--active" :disabled="!selectedEmail" @click="selectEmail" :title="$t('cancel')" text>
                                <span>{{$t('cancel')}}</span>
                                <v-icon>mdi-arrow-left-circle</v-icon>
                            </v-btn>
                        </template>
                        <span>{{$t('cancel')}}</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="v-btn--active" :disabled="!selectedEmail || loading" @click="printEmail" :title="$t('print')" text>
                                <span>{{$t('print')}}</span>
                                <v-icon>mdi-printer</v-icon>
                            </v-btn>
                        </template>
                        <span>{{$t('print')}}</span>
                    </v-tooltip>
                </v-bottom-navigation>
            </v-toolbar>
            <v-divider></v-divider>
            <transition name="fade" mode="out-in" v-if="loading">
                <v-sheet flat height="440">
                    <v-skeleton-loader type="list-item-two-line,divider,card-heading,list-item-avatar-two-line,list-item-three-line,divider,list-item"></v-skeleton-loader>
                </v-sheet>
            </transition>
            <transition name="fade" mode="out-in" v-else>
                <div id="print" class="maintable topaccent">
                    <table align="center" bgcolor="#ffffff" cellpadding="20" cellspacing="0" width="100%">
                        <tr>
                            <td align="left">
                                <div class="head">
                                    <table width="100%">
                                        <tr>
                                            <td><img class="logo" :src="storage_url+default_path+'logo.png'" width="220px" alt="Company Logo"/></td>
                                            <td align="right">
                                                <div class="printhead">
                                                    <span>{{$t("emailno")}} #{{selectedEmail.id}}</span><br>
                                                    <span style="padding: 5px 0 !important; display: block !important;">{{new Date() | formatDate}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="emailhead">
                                    <h4>
                                        {{selectedEmail.subject}}
                                    </h4>
                                    <table>
                                        <tr>
                                            <td style="vertical-align: middle;">
                                                <img :src="storage_url+default_path+'user.png'" alt="Avatar" class="avatar"> 
                                            </td>
                                            <td style="vertical-align: middle;">
                                                <div>
                                                    {{$t('from')}} <i style="color:#1976d2 !important;text-transform: capitalize;">{{selectedEmail.name}}</i> {{$t('on')}} <b>{{selectedEmail.created_at | formatTimeDate}}</b>
                                                    <br><span>{{selectedEmail.email}}</span>
                                                </div>
                                                
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="emailbody">
                                    <div class="preline">{{selectedEmail.content}}</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td align="center">
                                <div class="emailfooter">
                                    {{ new Date().getFullYear() }} — <strong>{{ company.title }}</strong>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </transition>
        </v-card>
  </v-container>
</template>

<script>
import { mapGetters,mapState } from "vuex";

export default({
    
    props: ['selectedEmail','loading'],
    data() {
        return {
            storage_url: process.env.VUE_APP_STORAGEURL,
            default_path: "default_images/",
        }
    },
    components: { },
    computed: {
        ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
        ...mapGetters('company', {company:'getCompany',}),
        ...mapState("company", {company_loading: "loading"}),
    },
    watch:{},
    methods: {
        selectEmail(){
            if(this.selectedEmail)
                this.$emit('selectEmail',this.selectedEmail);
        },
        printEmail(){
            if(this.selectedEmail){
                // Get HTML to print from element
                const prtHtml = document.getElementById('print').innerHTML;

                // Get all stylesheets HTML
                let stylesHtml =`
                    .maintable{
                        min-height:440px !important;
                        border-collapse: collapse !important; 
                        background-color: #ffffff !important;
                    }
                    .topaccent{
                        border-top: 4px solid #344a4e !important;
                    }
                    .logo{
                        width: 200px !important;
                    }
                    .head{
                        padding: 15px 20px 20px !important;
                    }
                    .printhead{
                        font-family: 'Open Sans',Helvetica,Arial,sans-serif !important;
                    }
                    .emailhead{
                        padding: 20px !important; 
                        border-top: 1px solid #f0f0f0 !important; 
                        border-bottom: 1px solid #f0f0f0 !important; 
                        background-color: #fafafa !important; 
                        -webkit-print-color-adjust: exact; 
                        font-family: 'Open Sans',Helvetica,Arial,sans-serif !important;
                    }
                    .emailhead h4{
                        margin: 10px 0 !important; 
                        color: #333 !important; 
                        font-weight: 500 !important; 
                        font-size: 24px !important;
                    }
                    .emailhead div{
                        line-height: 1.4 !important; 
                        font-size: 1.2 !important; 
                        font-size: 14px !important; 
                    }
                    .emailhead div span{
                        line-height: 1.4 !important; 
                        font-size: 1.2 !important; 
                        font-size: 14px !important; 
                        color: rgb(102, 102, 102) !important;
                    }
                    .emailbody{
                        padding: 20px 20px !important; 
                        font-family: 'Open Sans',Helvetica,Arial,sans-serif !important;
                        font-size: 16px !important;
                        line-height: 1.4 !important;
                        color: #333 !important;
                    }
                    .preline{
                        white-space: pre-line !important;
                    }
                    .emailfooter{
                        border-top: 1px solid #eaeaea !important;
                        font-family: 'Open Sans',Helvetica,Arial,sans-serif !important;
                        font-size: 14px !important;
                        line-height: 1.4 !important;
                        color: #777 !important;
                        padding:18px !important;  
                        position: absolute !important;
                        bottom: 0 !important;
                        left: 0 !important;
                        right: 0 !important;
                    }
                    .avatar {
                    vertical-align: middle !important;
                    width: 50px !important;
                    height: 50px !important;
                    border-radius: 50% !important;
                    margin: auto !important;
                    margin-right: 5px !important;
                    }`
                // Open the print window
                const WinPrint = window.open('', '', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=800,height=900');

                WinPrint.document.write(`<!DOCTYPE html>
                <html>
                <head>
                    <style type="text/css" media="print">
                        @page 
                        {
                            size: auto;   /* auto is the initial value */
                            margin: 0mm;  /* this affects the margin in the printer settings */
                        }
                        ${stylesHtml}
                    </style>
                </head>
                <body>
                    ${prtHtml}
                </body>
                </html>`);

                WinPrint.document.close();
                WinPrint.focus();
                WinPrint.print();
                WinPrint.close();
            }
        },
    },
})
</script>
<style type="text/css" media="print">
@page 
{
    size: auto;   /* auto is the initial value */
    margin: 0mm;  /* this affects the margin in the printer settings */
}
.maintable{
    min-height:440px !important;
    border-collapse: collapse !important; 
    background-color: #ffffff !important;
}
.topaccent{
    border-top: 4px solid #344a4e !important;
}
.logo{
    width: 200px !important;
}
.head{
    padding: 15px 20px 20px !important;
}
.printhead{
    font-family: 'Open Sans',Helvetica,Arial,sans-serif !important;
}
.emailhead{
    padding: 20px !important; 
    border-top: 1px solid #f0f0f0 !important; 
    border-bottom: 1px solid #f0f0f0 !important; 
    background-color: #fafafa !important; 
    -webkit-print-color-adjust: exact; 
    font-family: 'Open Sans',Helvetica,Arial,sans-serif !important;
}
.emailhead h4{
    margin: 10px 0 !important; 
    color: #333 !important; 
    font-weight: 500 !important; 
    font-size: 24px !important;
}
.emailhead div{
    line-height: 1.4 !important; 
    font-size: 1.2 !important; 
    font-size: 14px !important; 
}
.emailhead div span{
    line-height: 1.4 !important; 
    font-size: 1.2 !important; 
    font-size: 14px !important; 
    color: rgb(102, 102, 102) !important;
}
.emailbody{
    padding: 20px 20px 70px 20px !important; 
    font-family: 'Open Sans',Helvetica,Arial,sans-serif !important;
    font-size: 16px !important;
    line-height: 1.4 !important;
    color: #333 !important;
}
.preline{
    white-space: pre-line !important;
}
.emailfooter{
    border-top: 1px solid #eaeaea !important;
    font-family: 'Open Sans',Helvetica,Arial,sans-serif !important;
    font-size: 14px !important;
    line-height: 1.4 !important;
    color: #777 !important;
    padding:18px !important;  
    position: absolute !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
}
.avatar {
  vertical-align: middle !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  margin: auto !important;
  margin-right: 5px !important;
}
</style>
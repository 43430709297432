<template> 
    <v-container class="ma-auto pa-0" fluid v-if="selectedEmail">
      <v-form ref="form" v-model="valid" v-if="user && isLoggedIn" lazy-validation @submit.prevent="OpenSendEmail">
          <v-card class="mx-auto" outlined>
              <v-toolbar class="custom-light-gray" flat>
                  <v-bottom-navigation class="elevation-0 transparent">
                      <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" class="v-btn--active" :disabled="!selectedEmail" @click="selectEmail" :title="$t('cancel')" text>
                                  <span>{{$t('cancel')}}</span>
                                  <v-icon>mdi-arrow-left-circle</v-icon>
                              </v-btn>
                          </template>
                          <span>{{$t('cancel')}}</span>
                      </v-tooltip>
                      <v-spacer></v-spacer>
                      <span class="ma-auto title text--secondary">{{$t('forward')}}</span>
                      <v-spacer></v-spacer>
                      <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" class="v-btn--active" :disabled="!selectedEmail || loading || !valid" type="submit" :title="$t('send')" text>
                                  <span>{{$t('send')}}</span>
                                  <v-icon>mdi-send</v-icon>
                              </v-btn>
                          </template>
                          <span>{{$t('send')}}</span>
                      </v-tooltip>
                  </v-bottom-navigation>
              </v-toolbar>
              <v-divider></v-divider>
              <transition name="fade" mode="out-in" v-if="loading">
                  <v-sheet flat height="440">
                      <v-skeleton-loader type="card-heading,card-heading,article"></v-skeleton-loader>
                  </v-sheet>
              </transition>
              <transition name="fade" mode="out-in" v-else>
                  
                  <v-sheet flat min-height="440">
                      <div class="pa-4">
                          <v-text-field
                              v-model="NewItem.from"
                              color="custom-accent darken-4"
                              :label="$t('from')"
                              :placeholder="$t('from')"
                              outlined
                              required
                              dense
                              readonly
                              :counter="maxTextfieldLength"
                              :rules="emailfieldRules"
                          ></v-text-field>
                          <v-combobox 
                              v-model="NewItem.to"
                              color="custom-accent darken-4"
                              :label="$t('to')"
                              :placeholder="$t('to')"
                              outlined
                              required
                              dense
                              small-chips
                              multiple
                              :rules="requiredfieldRules"
                              @change="emailfieldValidate"
                          >
                              <template v-slot:append>
                                  <v-menu offset-y>
                                      <template v-slot:activator="{ on: menu, attrs }">
                                          <v-tooltip left>
                                              <template v-slot:activator="{ on }">
                                                  <v-icon class="custom-light-gray" v-bind="attrs" v-on="{ ...on, ...menu }" :title="$t('addrecipient')">mdi-plus</v-icon>
                                              </template>
                                              <span>{{$t('addrecipient')}}</span>
                                          </v-tooltip>
                                      </template>
                                      <v-list>
                                          <v-list-item @click="addRecipient('cc')" dense>
                                              <v-list-item-icon>
                                                  <v-icon>mdi-email</v-icon>
                                              </v-list-item-icon>
                                              <v-list-item-content>
                                                  <v-list-item-title>{{ $t("cc") }}</v-list-item-title>
                                              </v-list-item-content>
                                          </v-list-item>
                                          <v-list-item @click="addRecipient('bcc')" dense>
                                              <v-list-item-icon>
                                                  <v-icon>mdi-email</v-icon>
                                              </v-list-item-icon>
                                              <v-list-item-content>
                                                  <v-list-item-title>{{ $t("bcc") }}</v-list-item-title>
                                              </v-list-item-content>
                                          </v-list-item>
                                          <v-list-item @click="addRecipient('replyto')" dense>
                                              <v-list-item-icon>
                                                  <v-icon>mdi-email</v-icon>
                                              </v-list-item-icon>
                                              <v-list-item-content>
                                                  <v-list-item-title>{{ $t("replyto") }}</v-list-item-title>
                                              </v-list-item-content>
                                          </v-list-item>
                                      </v-list>
                                  </v-menu>
                              </template>
                              <template v-slot:selection="{ attrs, item, parent, selected }">
                                  <v-chip
                                  v-bind="attrs"
                                  :input-value="selected"
                                  label
                                  small
                                  >
                                      <span class="pr-2">
                                          {{ item }}
                                      </span>
                                      <v-icon
                                          small
                                          @click="parent.selectItem(item)">
                                          mdi-delete
                                      </v-icon>
                                  </v-chip>
                              </template>
                          </v-combobox>
  
                          <v-combobox v-if="cc"
                              v-model="NewItem.cc"
                              color="custom-accent darken-4"
                              :label="$t('cc')"
                              :placeholder="$t('cc')"
                              outlined
                              required
                              dense
                              small-chips
                              multiple
                              :rules="requiredfieldRules"
                              @change="emailfieldValidate"
                          >
                              <template v-slot:append>
                                  <v-tooltip left>
                                      <template v-slot:activator="{ on, attrs }">
                                          <v-icon class="custom-light-gray" v-bind="attrs" v-on="on" @click.stop="removeRecipient('cc')" :title="$t('close')">
                                              mdi-delete
                                          </v-icon>
                                      </template>
                                      <span>{{$t('close')}}</span>
                                  </v-tooltip>
                              </template>
                              <template v-slot:selection="{ attrs, item, parent, selected }">
                                  <v-chip
                                  v-bind="attrs"
                                  :input-value="selected"
                                  label
                                  small
                                  >
                                  <span class="pr-2">
                                      {{ item }}
                                  </span>
                                  <v-icon
                                      small
                                      @click="parent.selectItem(item)">
                                      mdi-delete
                                  </v-icon>
                                  </v-chip>
                              </template>
                          </v-combobox>
  
                          <v-combobox v-if="bcc"
                              v-model="NewItem.bcc"
                              color="custom-accent darken-4"
                              :label="$t('bcc')"
                              :placeholder="$t('bcc')"
                              outlined
                              required
                              dense
                              small-chips
                              multiple
                              :rules="requiredfieldRules"
                              @change="emailfieldValidate"
                          >
                              <template v-slot:append>
                                  <v-tooltip left>
                                          <template v-slot:activator="{ on, attrs }">
                                          <v-icon class="custom-light-gray" v-bind="attrs" v-on="on" @click.stop="removeRecipient('bcc')" :title="$t('close')">
                                              mdi-delete
                                          </v-icon>
                                      </template>
                                      <span>{{$t('close')}}</span>
                                  </v-tooltip>
                              </template>
                              <template v-slot:selection="{ attrs, item, parent, selected }">
                                  <v-chip
                                  v-bind="attrs"
                                  :input-value="selected"
                                  label
                                  small
                                  >
                                  <span class="pr-2">
                                      {{ item }}
                                  </span>
                                  <v-icon
                                      small
                                      @click="parent.selectItem(item)">
                                      mdi-delete
                                  </v-icon>
                                  </v-chip>
                              </template>
                          </v-combobox>
  
                          <v-combobox v-if="replyto"
                              v-model="NewItem.replyto"
                              color="custom-accent darken-4"
                              :label="$t('replyto')"
                              :placeholder="$t('replyto')"
                              outlined
                              required
                              dense
                              small-chips
                              multiple
                              :rules="requiredfieldRules"
                              @change="emailfieldValidate"
                          >
                              <template v-slot:append>
                                  <v-tooltip left>
                                          <template v-slot:activator="{ on, attrs }">
                                          <v-icon class="custom-light-gray" v-bind="attrs" v-on="on" @click.stop="removeRecipient('replyto')" :title="$t('close')">
                                              mdi-delete
                                          </v-icon>
                                      </template>
                                      <span>{{$t('close')}}</span>
                                  </v-tooltip>
                              </template>
                              <template v-slot:selection="{ attrs, item, parent, selected }">
                                  <v-chip
                                  v-bind="attrs"
                                  :input-value="selected"
                                  label
                                  small
                                  >
                                  <span class="pr-2">
                                      {{ item }}
                                  </span>
                                  <v-icon
                                      small
                                      @click="parent.selectItem(item)">
                                      mdi-delete
                                  </v-icon>
                                  </v-chip>
                              </template>
                          </v-combobox>
  
                          <v-text-field
                              v-model="NewItem.subject"
                              color="custom-accent darken-4"
                              :label="$t('subject')"
                              :placeholder="$t('subject')"
                              outlined
                              required
                              dense
                              :counter="maxTextfieldLength"
                              :rules="textfieldRules"
                          ></v-text-field>
                          <tiptap-vuetify
                              class="mt-4"
                              v-model="NewItem.content"
                              @focus="notFocused=false"
                              :extensions="extensions"
                              :placeholder="$t('content')"
                          ></tiptap-vuetify>
                          <v-row>
                              <v-col cols="8" v-if="!notFocused">
                                  <v-input
                                      :error-messages="textareaError(NewItem.content)"
                                      error>
                                  </v-input>
                              </v-col>
                              <v-col cols="auto" class="ml-auto">
                                  <v-input
                                      :error-messages="textareaCounter(NewItem.content)"
                                      error
                                      :disabled="textareaError(NewItem.content)==null || notFocused">
                                  </v-input>
                              </v-col>
                          </v-row>
                      </div>
                  </v-sheet>
              </transition>
          </v-card>
      </v-form>
  
      <!-- confirmation dialog -->
      <v-dialog v-model="sendemailoverlay" width="500px">
          <v-card>
          <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
              <span class="ma-auto">{{$t("sendemail")}}</span>
          </v-card-title>
          <v-card-text class="pa-4 ma-0">{{$t("sendconfirmation")}}</v-card-text>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="sendemailoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="success" :title="$t('send')" :loading="loading" depressed @click="sendEmail()">{{$t('send')}}</v-btn>
          </v-card-actions>
          </v-card>
      </v-dialog>
      <!-- confirmation dialog -->
    </v-container>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import { TiptapVuetify, History, Paragraph, Bold, Italic, Underline, Link, ListItem, BulletList, OrderedList, Heading, Strike,Blockquote, Code, HorizontalRule, HardBreak } from 'tiptap-vuetify';
  
  export default({
      
      props: ['selectedEmail','loading'],
      data() {
          return {
            storage_url: process.env.VUE_APP_STORAGEURL,
              valid:false,
              notFocused: true,
              extensions: [
                  History,
                  Paragraph,
                  Bold,
                  Italic,
                  Underline,
                  Link,
                  ListItem,
                  BulletList,
                  OrderedList,
                  [Heading, {
                      options: {
                      levels: [1, 2, 3]
                      }
                  }],
                  Strike,
                  Blockquote,
                  Code,
                  HorizontalRule,
                  HardBreak
              ],
              NewItem:{},
              cc:false,
              bcc:false,
              replyto:false,
              sendemailoverlay:false,
             
          }
      },
    
      components: { 
          TiptapVuetify,
      },
      created(){
          this.NewItem.from=this.company.email;
          this.NewItem.to=[];
          this.NewItem.cc=[];
          this.NewItem.bcc=[];
          this.NewItem.replyto=[this.user.email];
          this.NewItem.subject=`Fwd: `+this.selectedEmail.subject;
          this.NewItem.content= this.content;
      },
      computed: {
          content () {
            const resumeUrl = `${this.storage_url}/${this.selectedEmail.resume}`;
            return `<p></p>
                <p>--------------------- ${this.$t('forwardedmessage')} ---------------------</p>
                <p><strong>${this.$t('from')}: </strong>${this.selectedEmail.email} (${this.selectedEmail.name.toUpperCase()}) <br>
                <strong>${this.$t('date')}: </strong>${this.$options.filters.formatTimeDate(this.selectedEmail.created_at)}<br>
                <strong>${this.$t('to')}: </strong>${this.company.email} (${this.company.title}) <br>
                <strong>${this.$t('subject')}: </strong>${this.selectedEmail.subject}</p>
                <p><strong>${this.$t('content')}: </strong>${this.selectedEmail.content}</p>
                <p><strong>${this.$t('resume')}: </strong><a href="${resumeUrl}" download>Download Resume</a></p>`;
    },
          maxTextfieldLength () {
              return 200;
          },
          maxTextareaLength () {
              return 3500;
          },
          textfieldRules(){ 
              return [
                  v => !!v || this.$t('fieldError'),
                  v => v?.length <= this.maxTextfieldLength || this.$t('fieldValidError1')+' '+this.maxTextfieldLength+' '+this.$t('fieldValidError2'),
              ]
          },
          emailfieldRules(){ 
              const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
              return [
                  v => !!v || this.$t('fieldError'),
                  v => emailRegex.test(v) || this.$t('emailValidError'),
              ]
          },
          requiredfieldRules(){ 
              return [
                  v => !!v[0] || this.$t('fieldError'),
              ]
          },
          ...mapGetters('company',{company:'getCompany'}),
          ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
      },
      watch:{
          NewItem: {
              handler(){
                  this.validate();
              }, deep: true 
          },
      },
      mounted() {
    // Assuming all required data is ready and valid at this point
    this.sendEmail();
},
      methods: {
          textareaError(item){ 
              if(item?.replace(/<\/?[^>]+(>|$)/g, '').length==0 || !item)
              {
                  return this.$t('fieldError');
              }
              else if(item?.replace(/<\/?[^>]+(>|$)/g, '').length>this.maxTextareaLength){
                  return this.$t('fieldValidError1')+' '+this.maxTextareaLength+' '+this.$t('fieldValidError2');
              }
              return null;
          },
          textareaCounter(item){ 
              if(item?.replace(/<\/?[^>]+(>|$)/g, '').length){
                  return item.replace(/<\/?[^>]+(>|$)/g, '').length+'/'+this.maxTextareaLength;
              }
              return '0/'+this.maxTextareaLength;
          },
          validate(){
              let isValid = this.$refs.form.validate() && this.textareaError(this.NewItem.descriptionEN)==null && this.textareaError(this.NewItem.descriptionFR)==null && !this.notFocused;
              this.notFocused=false;
              this.valid=isValid;
          },
          emailfieldValidate(item){
              const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
              let emailValid = emailRegex.test(item.slice(-1));
              if(!emailValid)
                  item.pop()
          },
          addRecipient(recipient){
              switch (recipient) {
                  case 'cc':
                      this.cc=true;
                      break;
              
                  case 'bcc':
                      this.bcc=true;
                      break;
              
                  case 'replyto':
                      this.replyto=true;
                      break;
              
                  default:
                      break;
              }
          },
          removeRecipient(recipient){
              switch (recipient) {
                  case 'cc':
                      this.cc=false;
                      this.NewItem.cc=[];
                      break;
              
                  case 'bcc':
                      this.bcc=false;
                      this.NewItem.bcc=[];
                      break;
              
                  case 'replyto':
                      this.replyto=false;
                      this.NewItem.replyto=[this.user.email];
                      break;
              
                  default:
                      break;
              }
          },
          selectEmail(){
              if(this.selectedEmail)
                  this.$emit('selectEmail',this.selectedEmail);
          },
          OpenSendEmail(){
              this.sendemailoverlay = true;
          },
          sendEmail(){
              let isValid = this.$refs.form.validate() && this.textareaError(this.NewItem.descriptionEN)==null && this.textareaError(this.NewItem.descriptionFR)==null && !this.notFocused;
              console.log('Form valid:', isValid);
              if(this.selectedEmail && isValid)
              console.log('Emitting sendEmail with:', this.NewItem);
                  this.$emit('sendEmail',this.NewItem);
                  this.sendemailoverlay = false;
          },

      },
  })
  </script>
  <style scoped>
  </style>
  

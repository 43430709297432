var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-auto pa-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-toolbar',{staticClass:"custom-light-gray",attrs:{"flat":""}},[_c('v-toolbar-title',[_c('transition',{attrs:{"name":"slide-Y","mode":"out-in"}},[(_vm.Search.SearchBox)?_c('div',{key:"slide-1"},[_c('v-text-field',{staticClass:"search-box",attrs:{"label":_vm.$t('search'),"placeholder":_vm.$t('search'),"color":"custom-accent","rounded":"","clearable":"","outlined":"","dense":"","hide-details":"","return-object":""},on:{"click:clear":function($event){_vm.Search.SearchTerm='';_vm.search(_vm.Search.SearchTerm)},"keyup":function($event){return _vm.search(_vm.Search.SearchTerm)},"focus":function($event){_vm.Search.SearchFocus=true},"blur":function($event){_vm.Search.SearchFocus=false}},model:{value:(_vm.Search.SearchTerm),callback:function ($$v) {_vm.$set(_vm.Search, "SearchTerm", $$v)},expression:"Search.SearchTerm"}})],1):_c('v-menu',{attrs:{"transition":"slide-y-transition","content-class":"elevation-1","bottom":"","min-width":"250","rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0 pa-1 text--secondary",attrs:{"title":_vm.$t(_vm.selectedCategory.title),"text":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v(_vm._s(_vm.selectedCategory.icon))]),_c('span',{staticClass:"ma-auto mx-1"},[_vm._v(_vm._s(_vm.$t(_vm.selectedCategory.title)))]),_vm._v(" "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.selectedCategory.title)))])])]}}])},[_c('v-card',[_c('v-list',{attrs:{"text":""}},[_c('v-list-item-group',{attrs:{"mandatory":"","color":"primary"},model:{value:(_vm.selectedCategory.id),callback:function ($$v) {_vm.$set(_vm.selectedCategory, "id", $$v)},expression:"selectedCategory.id"}},_vm._l((_vm.categories),function(category,i){return _c('v-list-item',{key:i,attrs:{"value":category.id,"disabled":category.disabled,"dense":""},on:{"click":function($event){return _vm.setCategory(category)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(category.icon))])],1),_c('v-list-item-content',[_vm._v(_vm._s(_vm.$t(category.title)))])],1)}),1)],1)],1)],1)],1)],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":"","title":_vm.$t('search')},on:{"click":function($event){_vm.Search.SearchBox=!_vm.Search.SearchBox;_vm.Search.SearchTerm='';_vm.search(_vm.Search.SearchTerm)}}},'v-btn',attrs,false),on),[_c('transition',{attrs:{"name":"fade-rotate","mode":"out-in"}},[(_vm.Search.SearchBox)?_c('v-icon',{key:"icon-fade-1"},[_vm._v("mdi-close")]):_c('v-icon',{key:"icon-fade-2"},[_vm._v("mdi-magnify")])],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('search')))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","title":_vm.$t('refresh')},on:{"click":_vm.refresh}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('refresh')))])])],1),_c('v-divider'),(_vm.loading)?_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('v-sheet',{staticClass:"pa-0 ma-0 overflow-auto",attrs:{"height":"440","flat":""}},[_c('v-skeleton-loader',{attrs:{"type":"list-item-three-line"}}),_c('v-skeleton-loader',{attrs:{"type":"list-item-three-line"}}),_c('v-skeleton-loader',{attrs:{"type":"list-item-three-line"}})],1)],1):_vm._e(),(_vm.Search.SearchResult.length==0 && !_vm.loading)?_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('v-sheet',{staticClass:"text-center ma-0 pa-0",attrs:{"height":"440"}},[_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":"","fill-height":""}},[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"ma-0 pa-0"},[_c('i',{staticClass:"ma-0 pa-auto justify-center title font-weight-light text--secondary"},[_vm._v(_vm._s(_vm.$t("noresult"))+".")])])],1)],1)],1)],1):_vm._e(),(_vm.Search.SearchResult.length && !_vm.loading)?_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('v-list',{staticClass:"pa-0 overflow-auto",attrs:{"height":"440","two-line":""}},[_c('v-list-item-group',{attrs:{"active-class":"custom-accent--text"},on:{"change":_vm.selectEmail},model:{value:(_vm.selectedEmail),callback:function ($$v) {_vm.selectedEmail=$$v},expression:"selectedEmail"}},[_vm._l((_vm.Search.SearchResult.slice(0,_vm.itemsperpage * _vm.page)),function(item,i){return [_c('v-tooltip',{key:i,attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({key:item.name,class:item.status==0?'primary--text':item.status==2?'disabled-list':'',attrs:{"title":item.status==0? _vm.$t('new'):item.status==1?_vm.$t('seen'):item.status==2?_vm.$t('replied'):'',"value":item}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-capitalize",domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-subtitle',{staticClass:"text--primary text-capitalize",domProps:{"textContent":_vm._s(item.subject)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.content)}})],1),_c('v-list-item-action',[(item.status==0)?_c('v-badge',_vm._g(_vm._b({attrs:{"color":"primary","dot":""}},'v-badge',attrs,false),on)):_vm._e(),_c('v-list-item-action-text',[_vm._v(_vm._s(_vm._f("fromNow")(item.created_at)))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","title":_vm.$t('reply')},on:{"click":function($event){$event.stopPropagation();return _vm.replyEmail(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":item.status==0?'primary':''}},[_vm._v("mdi-reply")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('reply')))])])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.status==0? _vm.$t('new'):item.status==1?_vm.$t('seen'):item.status==2?_vm.$t('replied'):''))])]),_c('v-divider',{key:'divider-'+i})]})],2),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"ma-auto pa-0",attrs:{"cols":"auto"}},[(_vm.itemsperpage*_vm.page < _vm.Search.SearchResult.length && !_vm.loading)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-auto",attrs:{"icon":"","x-large":"","title":_vm.$t('viewmore')},on:{"click":function($event){$event.stopPropagation();return _vm.viewmore.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,1255197134)},[_c('span',[_vm._v(_vm._s(_vm.$t('viewmore')))])]):_vm._e(),(_vm.itemsperpage*_vm.page >= _vm.Search.SearchResult.length && !_vm.loading && _vm.page>1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-auto",attrs:{"icon":"","x-large":"","title":_vm.$t('viewless')},on:{"click":function($event){$event.stopPropagation();return _vm.viewless.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-chevron-up")])],1)]}}],null,false,1511264185)},[_c('span',[_vm._v(_vm._s(_vm.$t('viewless')))])]):_vm._e()],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }